<template>
    <div class="page">
        <!-- 查询页面 -->
        <div class="page-form"
             v-if="isQuery">
            <div class="title">
                <div class="label">互联网数据服务查询</div>
                <div class="label">CERTIFICA TE QUERY</div>
            </div>
            <Form ref="form"
                  :model="form"
                  :rules="rule">
                <FormItem prop="userName">
                    <Input type="text"
                           v-model.trim="form.userName"
                           placeholder="姓名 Name">
                    <template #prepend>
                        <Icon type="ios-person-outline"></Icon>
                    </template>
                    </Input>
                </FormItem>
                <FormItem prop="cardNo">
                    <Input type="text"
                           v-model.trim="form.cardNo"
                           placeholder="身份证号码 Identity Card Code">
                    <template #prepend>
                        <Icon type="ios-lock-outline"></Icon>
                    </template>
                    </Input>
                </FormItem>
                <FormItem>
                    <Button style="width: 100%; margin: 10px 0;"
                            type="primary"
                            @click="handleSubmit">查询 Query</Button>
                    <Button style="width: 100%;"
                            type="primary"
                            @click="initForm"
                            class="empty">清空
                        Empty</Button>

                </FormItem>
            </Form>
        </div>
        <!-- 结果页 -->
        <div class="result"
             v-else>
            <div class="header">
                <img src="../../assets/q-logo.jpg"
                     alt=""
                     srcset="">
                <div>
                    <div>深圳市中家职业技能培训学校</div>
                    <div>(互联网数据服务)</div>
                </div>
            </div>
            <div class="content">
                <div class="left">
                    <div class="top-title">查询结果</div>
                    <div class="card">
                        <div class="title">
                            <img src="../../assets/q-id.png"
                                 alt="">
                            基本信息
                        </div>
                        <div class="box top">
                            <div>
                                <div class="info">
                                    <div class="label">姓名</div>
                                    <div class="label-info">{{ user.name }}</div>
                                </div>
                                <div class="info">
                                    <div class="label">身份证号</div>
                                    <div class="label-info">{{ user.cardNo }}</div>
                                </div>
                                <div class="info">
                                    <div class="label">性别</div>
                                    <div class="label-info">{{ user.sex === 0 ? '男' : user.sex === 1 ? '女' : '保密' }}</div>
                                </div>
                                <div class="info">
                                    <div class="label">地址</div>
                                    <div class="label-info">{{ user.address }}</div>
                                </div>
                            </div>
                            <img  v-if="user.picture"
                                 :src="user.picture"
                                 alt="">
                        </div>
                    </div>
                    <div class="card"
                         v-for="(item, index) in user.certList"
                         :key="index">
                        <div class="title">
                            <img src="../../assets/q-id.png"
                                 alt="">
                            证书信息
                        </div>
                        <div class="box center">
                            <div>
                                <div class="info">
                                    <div class="label">证书编号</div>
                                    <div class="label-info">{{ item.certificateNo }}</div>
                                </div>
                                <div class="info">
                                    <div class="label">发证日期</div>
                                    <div class="label-info">{{ item.certificateReleaseTime }}</div>
                                </div>
                                <div class="info">
                                    <div class="label">职业名称</div>
                                    <div class="label-info">{{ item.subjectName }}</div>
                                </div>
                                <div class="info">
                                    <div class="label">组织单位或培训机构</div>
                                    <div class="label-info">{{ item.organizationName }}</div>
                                </div>
                            </div>
                            <div>
                                <div class="info">
                                    <div class="label">级别</div>
                                    <div class="label-info">{{ item.level }}</div>
                                </div>
                                <div class="info">
                                    <div class="label">理论知识考核成绩</div>
                                    <div class="label-info">{{ item.examineScore }}</div>
                                </div>
                                <div class="info">
                                    <div class="label">实操知识考核成绩</div>
                                    <div class="label-info">{{ item.actualScore }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="text-align: center;">
                        <Button style="width: 200px;"
                                size="large"
                                type="primary"
                                @click="continueClick">继续查询</Button>
                    </div>
                </div>
                <div class="right">
                    深圳市中家职业技能培训学校是由市人力资源和社会保障局批准成立的专业家政技能培训机构。培训场地2000余平方米，是政府支持的深圳市首家家政服务技能培训机构。学校开设了包括家政技能培训在内的及社会需求的多元化培训项目。具有一流的先进教学设备、温馨的学习环境、专业的师资力量、大规模的实操场地，填补了中国家政行业技能培训的空白。从2009年开办以来，先后荣获了罗湖区家政培训基地、深圳市高技能人才培训基地和广东省家庭服务培训示范基地等荣誉。
                    学校本着“以诚信求质量，以质量求发展”的办学理念；以市场为导向；以服务于社会，服务于学员为目的；坚持严谨、务实、创新、不断发展的办学宗旨；坚持高标准、高质量、一切为了学员，优质服务社会的原则；对学员进行专业化、系统化、规范化的培训；实行“理论与实践相结合”一体化的教学模式；使学员都能掌握技能，学到本领，提升素质，增加价值。
                    深圳市中家职业技能培训学校将以严谨的教学、科学的管理、务实的工作、竭诚为学员服务、恪守对客户负责人、对社会负责人、对学员负责人的承诺，努力为产业报国、服务社会、和谐社会、做出不懈的努力。
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';

const rule = {
    userName: [
        { required: true, message: '请填写姓名', trigger: 'blur' }
    ],
    cardNo: [
        { required: true, message: '请填写身份证号码', trigger: 'blur' },
    ]
}
export default {
    data() {
        return {
            isQuery: true,
            form: {
                userName: '',//曹士衡
                cardNo: ''//411323198407111111
            },
            user: {},
            rule
        }
    },
    mounted() {
       //this.handleSubmit()
    },
    methods: {
        handleSubmit() {
            if(!this.form.userName) {
                return this.$Message.warning('请填写要查询的姓名')
            }
            if(!this.form.cardNo) {
                return this.$Message.warning('请填写要查询的身份证号码')
            }
            // https://org.zhongjiapx.com/exam/certApi/findCert
            // http://52jz.natapp4.cc/exam/certApi/findCert
            axios.get('https://org.zhongjiapx.com/exam/certApi/findCert', {
                params: this.form
            }).then(response => {
                const { responseCode, data } = response?.data || {}
                if (responseCode === '100') {
                    if (data.resultCode === '1') {
                        this.user = data
                        this.isQuery = false;
                        console.log(this.user)
                    } else {
                        return this.$Message.error(data.resultMsg || '查询失败')
                    }

                } else {
                    this.$Message.error('请求失败')
                }
            }).catch(error => {
                console.error('There was an error!', error);
                this.$Message.error('请求失败')
            });
        },
        initForm() {
            this.form = {
                userName: '',
                cardNo: ''
            }
        },
        continueClick() {
            this.isQuery = true;
            this.initForm()
        }
    }
}
</script>
<style scoped lang="less">
    /deep/.ivu-btn-primary{
        height: 40px!important;
        font-size: 14px!important;
        background: #0052D9!important;
    }
    /deep/.ivu-input{
        height: 40px!important;
        font-size: 14px!important;
    }
    .page {
        position: relative;
        width: 100%;
        height: 100%;
        position: relative;
    }

    .page-form {
        width: 340px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }

    .page-form .title {
        margin-bottom: 20px;
        color: #666;
    }

    .page-form .label {
        font-size: 30px;
    }

    .result {
        padding: 15px;
        width: 100%;
    }

    .header {
        text-align: center;
        font-size: 26px;
        width: 446px;
        display: flex;
        justify-content: space-between;
        margin: 10px auto;

        img {
            width: 70px;
            height: 70px;
            align-items: center;
        }
    }

    .content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .right {
            width: 250px;
            font-size: 14px;
            background: #d9e1ff;
            text-indent: 60px;
            padding: 15px 10px 20px 10px;
            border-radius: 20px;
            font-weight: 700;
        }

        .left {
            flex: 1;

            .top-title {
                font-size: 18px;
                font-weight: 700;
                text-align: center;
                margin-bottom: 10px;
            }

            .card {
                font-size: 16px;
                margin-bottom: 15px;
                border: 1px solid #ddd;
                border-radius: 10px;
                margin-right: 20px;
            }

            .title {
                background: #002a7c;
                color: #fff;
                height: 40px;
                line-height: 40px;
                padding-left: 20px;
                border-radius: 10px 10px 0 0;
                display: flex;
                align-items: center;

                img {
                    width: 20px;
                    height: 20px;
                    margin-right: 15px;
                }
            }

            .box {
                padding: 10px 20px 0 20px;
            }

            .top {
                display: flex;
                justify-content: space-between;

                >div {
                    width: 80%;
                }

                >img {
                    width: 150px;
                    height: 170px;
                    object-fit: cover;
                }
            }

            .center {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                >div {
                    width: 49%;
                }
            }

            .info {
                background: #f9f9f9;
                margin-bottom: 10px;
                display: flex;
                height: 38px;
                font-size: 16px;
                font-weight: 700;
                line-height: 38px;
            }

            .label {
                background: #d9e1ff;
                width: 200px;
                text-align: center;
                border-radius: 6px 0 0 6px;
                margin-right: 20px;
                line-height: 38px;
            }
            .label-info{
                flex: 1;
            }
        }
    }

    @media screen and (max-width: 860px) {
        .result .header{
            width: 100%!important;
            font-size: 20px
        }
        .content .right {
            width: 100%;
            margin-top: 20px;
        }

        .content .left .card {
            margin-right: 0;
        }

        .page-form {
            left: 9%!important;
            top: 25%!important;
            transform: translate(0,0)!important;
        }

        .left {
            .box{
                padding: 10px 10px 0 10px!important;
            }
            .top {
                >div {
                    width: 100%!important;
                    box-sizing: content-box;
                }

                >img {
                    width: 85px!important;
                    height: 100px!important;
                    object-fit: cover;
                }
            }
            .center{
                display: block!important;
                >div {
                    width: 100%!important;
                }
            }
        }

        .info {
            width: 100%!important;
            font-size: 12px!important;
            display: flex;
            align-items: center;
            line-height: 1.5!important;
            .label {
                width: 115px!important;
                margin-right: 5px!important;
            }
        }

    }

    .empty {
        background: #618DFF !important;
    }
</style>