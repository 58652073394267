<template>
  <div id="app">
    <searchPage />
  </div>
</template>

<script>
import searchPage from './components/searchPage/searchPage.vue'

export default {
  name: 'App',
  components: {
    searchPage
  },
  methods: {
  }
}
</script>

<style>
html,
body,
#app {
  width: 100%!important;
}
</style>
